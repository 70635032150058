import "./style.scss"

import 'master'
import 'slick-carousel';
import AOS from 'aos';

var _isMobiClass = 'isMobi',
  _isPopupClass = 'isPopup';
var _isDesktopSize = 1100;
var _inputCheckedClass ='box-checked';

// $(window).on('scroll',function(){

//   var lastScrollTop = 0;
//   var $targetFiltet = $('.comp-filter-sports');

//   if ($targetFiltet.is(':visible')) {
//     var _targetFilterPos = Math.round($targetFiltet.offset().top+$targetFiltet.find('._wrapper').outerHeight());
//     var st = $(this).scrollTop()+$(window).height();
//     var _pgBtmPos = Math.round(($('footer .btm-bar').offset().top));

//     if($(this).scrollTop()==0 && $(window).width()>1119){
//       $('html').addClass('homepage-init');
//     }else{
//       $('html').removeClass('homepage-init');
//     };

//       if ($('.btn-filter.hide-mobi').hasClass('on')) {
//         $('html').addClass('filter-scroll-down');
//         if ($(window).width()>1119) {
//           if (st >= _pgBtmPos) {
//             $('html').addClass('pg-bottom');
//             $('.comp-filter-sports ._wrapper').css('margin-bottom',290); //Math.round(st*0.03)
//           }else{
//           $('html').removeClass('pg-bottom');
//           $('.comp-filter-sports ._wrapper').css('margin-bottom',0);
//           }
//         }
//       }else{
//         $('html').removeClass('filter-scroll-down');
//       }
//     //scroll home filter
//     if ($(window).width()>1119 && st < _targetFilterPos) {
//        if (st > lastScrollTop){
//            $('html').removeClass('filter-scroll-down');
//            //console.log('scroll down');
//        }
//        lastScrollTop = st;
//     }
//   }

// });

$(function() {
  topSlider();
  latestClubSlider();
  chairmanMsgSlider();
  officialPartnesSlider();
  upcomingEventSlider();
  sportHighlightsSlider();
  openVideoLB();
  fandbTab();
  diningHighlightSlider()
  eventCelebrate();

  AOS.init({    
    duration: 1000
  });
  
});

$(window).on('load', function() {
  
});

function topSlider(){
  var totalNumber;
  var currentNum;
  $('.hpHeroBanner-slider').on("init", function(event, slick) {
    // console.log(slick.slideCount);
    // //console.log(slick.currentSlide+1);
    // totalNumber = slick.slideCount;
    // currentNum = slick.currentSlide + 1;
        
    setTimeout(function() {
      $(".hpHeroBanner-slider .slick-active video")[0].play();
      console.log("hihih")
    },5000) 
  });

  $('.hpHeroBanner-slider').not('.slick-initialized').slick({
      autoplay: true,
      autoplaySpeed: 5000,
      dots : true,
      adaptiveHeight: true,
      arrows: false, 
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1      
  });

  

  // $('.hpHeroBanner-slider').on("afterChange", function(event, slick, currentSlide) {
  //   console.log(currentSlide);
  //   currentNum = currentSlide + 1;
    
  // });

}

function latestClubSlider(){
  
  $('.latestCN-slider').not('.slick-initialized').slick({
      dots : true,
      adaptiveHeight: true,
      arrows: true, 
      infinite: false,
      slidesToShow: 3.5,
      slidesToScroll: 1,
      prevArrow: $('.laterCubNews .slider-prev'),
      nextArrow: $('.laterCubNews .slider-next'),
      responsive: [
        {
            breakpoint: 769,
            variableWidth: false,
            settings: {
                slidesToShow: 2.5,
                slidesToScroll: 1
            }
        },
        {
          breakpoint: 481,
          variableWidth: false,
          settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1
          }
        }
      ]
  });
  // $(".latestCN-slider .slider-prev").click(function() {
  //   $(".latestCN-slider .slick-prev").trigger("click");
  // });
  // $(".latestCN-slider .slider-next").click(function() {
  //   $(".latestCN-slider .slick-next").trigger("click");
  // });
}

function chairmanMsgSlider() {  
  $('.chairmanMsg-slider').not('.slick-initialized').slick({
      dots : true,
      adaptiveHeight: true,
      arrows: true, 
      infinite: false,
      slidesToShow: 2.5,
      slidesToScroll: 1,
      prevArrow: $('.chairmanMessage  .slider-prev'),
      nextArrow: $('.chairmanMessage  .slider-next'),
      responsive: [
        {
          breakpoint: 769,
          variableWidth: false,
          settings: {
              slidesToShow: 2.5,
              slidesToScroll: 1
          }
        },
        {
          breakpoint: 481,
          variableWidth: false,
          settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1
          }
        }
      ]
  });
}

function officialPartnesSlider() {  
  $('.officialPartners-slider').not('.slick-initialized').slick({
      dots : false,
      adaptiveHeight: true,
      arrows: true, 
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      prevArrow: $('.officialPartners .slider-prev'),
      nextArrow: $('.officialPartners .slider-next'),
      responsive: [
          {
              breakpoint: 769,
              variableWidth: false,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
              }
          }
      ]
  });
}
function upcomingEventSlider() {  
  $('.upcomingEvent-slider').not('.slick-initialized').slick({
      dots : true,
      adaptiveHeight: true,
      arrows: true, 
      infinite: false,
      slidesToShow: 4.2,
      slidesToScroll: 1,
      prevArrow: $('.upcomingEvent .slider-prev'),
      nextArrow: $('.upcomingEvent .slider-next'),
      responsive: [          
        {
            breakpoint: 768,
            variableWidth: false,
            settings: {
                slidesToShow: 2.2,
                slidesToScroll: 1
            }
        },
        {
          breakpoint: 400,
          variableWidth: false,
          settings: {
              slidesToShow: 1.2,
              slidesToScroll: 1
          }
        }
      ]
  });
}
function sportHighlightsSlider() {  
  $('.sportHighlights-slider').not('.slick-initialized').slick({
      dots : false,
      adaptiveHeight: true,
      arrows: true, 
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,      
      prevArrow: $('.sportHighlights .slider-prev'),
      nextArrow: $('.sportHighlights .slider-next')      
  });
}

function openVideoLB() {
  $(".videoCTA").each(function() {
    $(this).click(function() {
      console.log($(this).attr("data-video"));
      $(".lightbox-wrap").addClass("active");
      $("html").addClass("overflowHidden");
      var video = $('.lightbox-wrap video')[0];
      video.src = $(this).attr("data-video");
      video.load();
      video.play();

    })
  });
  $(".closeBtn").click(function() {
    $(".lightbox-wrap").removeClass("active");
    $("html").removeClass("overflowHidden");
  });
}

function fandbTab() {
  $(".restTabItem").eq(0).addClass("on");
  $(".fAndBTabNav a").eq(0).addClass("on");
  $(".fAndBTabNav a").each(function(i) {
    $(this).click(function() {
      $(".restTabItem").removeClass("on");
      $(".fAndBTabNav a").removeClass("on");
      $(this).addClass("on");
      $(".restTabItem").eq(i).addClass("on");
    })
  })
}
function diningHighlightSlider() {  
  $('.diningHighlight-slider').not('.slick-initialized').slick({
      dots : true,
      adaptiveHeight: true,
      arrows: true, 
      infinite: false,
      slidesToShow: 3.2,
      slidesToScroll: 1,
      prevArrow: $('.box-slider-prev'),
      nextArrow: $('.box-slider-next'),
      responsive: [          
          {
              breakpoint: 1440,
              variableWidth: false,
              settings: {
                  slidesToShow: 3.2,
                  slidesToScroll: 1
              }
          },
          /*{
            breakpoint: 768,
            variableWidth: false,
            settings: {
                slidesToShow: 2.2,
                slidesToScroll: 1,
                prevArrow: $('.box-slider-prevM'),
                nextArrow: $('.box-slider-nextM')
            }
          },
          {
            breakpoint: 481,
            variableWidth: false,
            settings: {
                slidesToShow: 1.2,
                slidesToScroll: 1,
                prevArrow: $('.box-slider-prevM'),
                nextArrow: $('.box-slider-nextM')
            }
          }*/
          {
            breakpoint: 768,
            variableWidth: false,
            settings: {
                slidesToShow: 2.2,
                slidesToScroll: 1,
                prevArrow: $('.box-slider-prevM'),
                nextArrow: $('.box-slider-nextM')
            }
          },
          {
            breakpoint: 400,
            variableWidth: false,
            settings: {
                slidesToShow: 1.2,
                slidesToScroll: 1,
                prevArrow: $('.box-slider-prevM'),
                nextArrow: $('.box-slider-nextM')
            }
          }
      ]
  });
}
function eventCelebrate() {
  $('.eCGallery-slider').not('.slick-initialized').slick({
    dots : true,
    adaptiveHeight: true,
    arrows: false, 
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  });

  $('.eCVenue-slider').not('.slick-initialized').slick({
    dots : true,
    adaptiveHeight: true,
    arrows: true, 
    infinite: false,
    slidesToShow: 3.2,
    slidesToScroll: 1,
    prevArrow: $('.eventCelebrate .slider-prev'),
    nextArrow: $('.eventCelebrate .slider-next'),
    responsive: [          
        {
            breakpoint: 768,
            variableWidth: false,
            settings: {
                slidesToShow: 2.2,
                slidesToScroll: 1
            }
        },
        {
          breakpoint: 480,
          variableWidth: false,
          settings: {
              slidesToShow: 1.2,
              slidesToScroll: 1
          }
      }
    ]
  });

  
}


function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(location.href);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, '    '));
};

function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toGMTString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}



$(window).on('resize', function() {
  var win = $(this); //this = window
  if (win.width() >= _isDesktopSize) {
    //console.log('it's desktop');
  } else {
    //console.log('it's mobile');
    if ($('.btn-filter.hide-mobi').hasClass('on')) {
      $('.btn-filter.hide-mobi').trigger('click');
    }
  }

  if ($(".popup-wrapper .inner ul").height() > $(".popup-wrapper .inner").height()) {
    $(".popup-wrapper").removeClass("noArrow");
  } else {
    $(".popup-wrapper").addClass("noArrow");
  }
});

import "./style.scss"

var _selClass = "on",
    _popupClass = "isPopup",
    _popupBgClass = 'hasBg';

var lastScrollTop = 0;


var _rootEshopAPIurl = (process.env.NODE_ENV == 'production')?  '':'http://13.75.104.238';


$(window).on('scroll',function(){

  $('header menu .sub-panel').hide();

  var _footerPos = $('footer').position().top;
  var st = $(this).scrollTop()+$(window).height();

  if ($(window).width()>1119 && st < _footerPos) {
     if (st > lastScrollTop && $(this).scrollTop()>0){
         $('html').addClass('pg-scroll-down');
     } else {
       $('html').removeClass('pg-scroll-down');
     }
     lastScrollTop = st;
  }

  // scroll home banner
  // this.console.log("scroll:" + $(this).scrollTop())
  if ($('.home .promo-banner').is(':visible')) {

    var tempccc = $('.home .promo-banner').position().top + 100;
    // this.console.log("link:" + $('.home .comp-news-and-events a.arrowLink').position().top)
    if ($(this).scrollTop() > tempccc ) {
      $('html').addClass('stickyAdv');
      if ($(this).scrollTop() < $('.home .comp-news-and-events a.arrowLink').position().top) {
        $('html').removeClass('stickyAdv');
      }
    } else if ($(this).scrollTop() < 140) {
      $('html').removeClass('stickyAdv');
    } else {
      $('html').removeClass('stickyAdv');
    }

  }

});

$(function() {

  mobiSidebar();
  topSubpanel();

  // if ($('html').hasClass('eshop')) {
  //   eshopFn();
  // }
  eshopFn();

  //footer cookie function
  footerCookie();


  $('header .list-bar .icon').mouseover(function(){
      $(this).parent().find('span').addClass(_selClass);
  }).mouseout(function(){
      $(this).parent().find('span').removeClass(_selClass);
  });

  $('header menu > li').mouseover(function(){
    $(this).addClass(_selClass);
    $(this).find('.sub-panel').show();
  }).mouseout(function(){
    $(this).removeClass(_selClass);
  })

  $('header .mobi-top-panel .list-sports, header .mobi-top-panel .sub-panel').each(function(){
    var _panelStr = $(this).html();
    $(this).append('<div class="scrollbar-dynamic"></div>');
    $(this).find('.scrollbar-dynamic').html(_panelStr);
    $(this).find('>a').remove();
  });

  $('.comp-logout').click(function(){
    return false;
  });


});

function mobiSidebar(){

  var $mobilPanel = $('header .mobi-top-panel'),
      _menuType = ['type-links','type-sports'];

  var $sideNavItem = $('header .mobi-top-panel menu li');
  var $btnSports = $('header .mobi-top-panel .btn-sports-list');

  // $('>.link',$sideNavItem).click(function(){
  //   $sideNavItem.removeClass(_selClass);
  //   $(this).parent().addClass(_selClass);
  //   $mobilPanel.removeClass(_menuType[1]).addClass(_menuType[0]);
  //   if($btnSports.hasClass(_selClass)){
  //     $btnSports.trigger('click');
  //   }
  // })

  $('>p.link',$sideNavItem).on("click touchstart", function() {
    $sideNavItem.removeClass(_selClass);
    $(this).parent().addClass(_selClass);
    $mobilPanel.removeClass(_menuType[1]).addClass(_menuType[0]);
    if($btnSports.hasClass(_selClass)){
      $btnSports.trigger('click');
    }
    return false;
  });

  // $btnSports.click(function(){
  //   if(!$(this).hasClass(_selClass)){
  //     $(this).addClass(_selClass);
  //     $('.list-sports').fadeIn();
  //     $mobilPanel.removeClass(_menuType[0]).addClass(_menuType[1]);
  //     $('header .mobi-top-panel menu li.on').removeClass(_selClass);
  //   }else{
  //     $(this).removeClass(_selClass);
  //     $('.list-sports').fadeOut();
  //   }
  // })

  $btnSports.on("click touchstart", function() {
    if(!$(this).hasClass(_selClass)){
      $(this).addClass(_selClass);

      $('.list-sports').fadeIn();
      $mobilPanel.removeClass(_menuType[0]).addClass(_menuType[1]);
      $('header .mobi-top-panel menu li.on').removeClass(_selClass);
    }else{
      $(this).removeClass(_selClass);
      $('.list-sports').fadeOut();
    }
    return false;
  });

  $('header .nav-btn').click(function(){
    $(this).toggleClass(_selClass);
    if ($(this).hasClass(_selClass)) {
      $('.mobi-top-panel').fadeIn();
      $('.mobi-top-panel menu li:eq(0)>.link').trigger('click');
      $('html').addClass(_popupClass);
      $('html').addClass(_popupBgClass);
      if ($('.comp-filter-sports').hasClass('isPopup')) {
        $('.comp-filter-sports.isMobi').removeClass('isPopup');
        $('.opt-list').removeClass('on');
      }
    }else{
      $('.mobi-top-panel').fadeOut();
      $('html').removeClass(_popupClass);
      $('html').removeClass(_popupBgClass);
    }
  })

}

function topSubpanel(){
  var colNum = 3;
  var $colVert = $('header .outer-wrapper > menu .col-links.vertical');
  $colVert.each(function(){
    var _total = $(this).find('a').length;
    var count = 5;
    var column = 0;
    for (var i = 0; i < _total; i += count) {
      column++;
      $(this).append('<nav class="col' + column + '"></nav>');
      $(this).find(".col" + column).html($(this).find(">a").splice(0, count));
    }
    if ($(this).find('.newCol')) {
      var _linkIndex = $(this).find('.newCol').index();
      var $navParent = $(this).find('.newCol').parent();
      var _parentIndex = $navParent.index();
      var _clonedLinks = $navParent.find('a').slice(_linkIndex,$navParent.find('a').length);

      $(this).find('nav').eq(_parentIndex+1).prepend(_clonedLinks);

    }

  })


}

function eshopFn(){

    var _apiUrl = _rootEshopAPIurl + '/Umbraco/api/Eshop/GetCart';


    var _apiData;

    $.ajax({
      type: 'GET',
      url: _apiUrl,
      dataType: "json",
      contentType: "application/x-www-form-urlencoded",
      success: function success(response) {
        //Load API content
        if (response.Code == 200) {
          _apiData = response;
        }

        if (_apiData.Data.length == 0) {
          $('header .btn-cart').removeClass('activated');
        } else {
          $('header .btn-cart').addClass('activated');
          $('header .btn-cart .numItem').text(_apiData.Data.length);
        }

      }
    });


}

function footerCookie() {
  $(".footerCookiesBanner").addClass("active");
  $(".closeCookieBanner").bind("click", function() {
    $(".footerCookiesBanner").removeClass("active");
  });
}